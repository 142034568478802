import { DataTable } from "../View/SubTopic/Tables/TabularComponents/DataTable";
import { ToggleTableView } from "../View/SubTopic/Tables/TabularComponents/ToggleTableView";
import { MultiDataTable } from "../View/SubTopic/Tables/TabularComponents/MultiDataTable";
import { WorkProgress } from "../View/Home/WorkProgress";
import { ToggleCommonTableView } from "../View/SubTopic/Tables/CommonTabularComponents/ToggleTableView";

export const TABLE_MAPPING = [
    {
        "subtopic": 1,
        "cards": [
            {
                "chartId": 1,
                "component": DataTable,
            },
            {
                "chartId": 2,
                "component": ToggleTableView
            },
            {
                "chartId": 3,
                "component": ToggleTableView
            },
            {
                "chartId": 4,
                "component": ToggleTableView
            },
            {
                "chartId": 5,
                "component": ToggleTableView
            },
            {
                "chartId": 6,
                "component": DataTable
            },
            {
                "chartId": 7,
                "component": MultiDataTable
            },
            {
                "chartId": 8,
                "component": ToggleTableView
            },
            {
                "chartId": 9,
                "component": ToggleTableView
            },
            {
                "chartId": 10,
                "component": DataTable
            }
        ]
    },
    {
        "subtopic": 2,
        "cards": [
            {
                "chartId": 11,
                "component": DataTable
            },
            {
                "chartId": 12,
                "component": WorkProgress
            },
            {
                "chartId": 13,
                "component": DataTable
            },
            {
                "chartId": 14,
                "component": WorkProgress
            },
            {
                "chartId": 15,
                "component": DataTable
            },
            {
                "chartId": 16,
                "component": DataTable
            },
            {
                "chartId": 17,
                "component": WorkProgress
            },
            {
                "chartId": 18,
                "component": WorkProgress
            },
            {
                "chartId": 19,
                "component": WorkProgress
            },
            {
                "chartId": 20,
                "component": ToggleTableView,//DataTable
            },
            {
                "chartId": 21,
                "component": WorkProgress
            },
            {
                "chartId": 22,
                "component": WorkProgress
            },
            {
                "chartId": 23,
                "component": DataTable
            },
            {
                "chartId": 24,
                "component": DataTable
            },
            {
                "chartId": 25,
                "component": WorkProgress
            },
            {
                "chartId": 26,
                "component": WorkProgress
            },
            {
                "chartId": 27,
                "component": WorkProgress
            },
            {
                "chartId": 28,
                "component": WorkProgress
            },
            {
                "chartId": 29,
                "component": WorkProgress
            },
            {
                "chartId": 30,
                "component": WorkProgress
            },
            {
                "chartId": 31,
                "component": WorkProgress
            },
            {
                "chartId": 32,
                "component": ToggleTableView,
            },
            {
                "chartId": 33,
                "component": WorkProgress,
            },
            {
                "chartId": 34,
                "component": WorkProgress
            },
            {
                "chartId": 35,
                "component": WorkProgress
            },
            {
                "chartId": 36,
                "component": WorkProgress
            },
            {
                "chartId": 37,
                "component": WorkProgress
            },
            {
                "chartId": 38,
                "component": WorkProgress
            },
        ]
    },
    {
        "subtopic": 3,
        "cards": [
            {
                "chartId": 39,
                "component": ToggleCommonTableView,//DataTable
            },
            {
                "chartId": 40,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 41,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 44,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 52,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 53,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 54,
                "component": ToggleCommonTableView, //ToggleTableView
            },
            {
                "chartId": 55,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 56,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 57,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 59,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 60,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 61,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 62,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 63,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 64,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 65,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 66,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 67,
                "component": ToggleCommonTableView
            },
        ]
    },
    {
        "subtopic": 4,
        "cards": [
            {
                "chartId": 68,
                "component": ToggleCommonTableView, //DataTable
            },
            {
                "chartId": 69,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 70,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 71,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 72,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 73,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 74,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 75,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 76,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 77,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 78,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 79,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 80,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 81,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 82,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 83,
                "component": ToggleCommonTableView,//ToggleTableView
            },
            {
                "chartId": 84,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 85,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 86,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 87,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 88,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 89,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 90,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 91,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 92,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 93,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 94,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 95,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 96,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 97,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 98,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 99,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 100,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 101,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 102,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 103,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 104,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 105,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 106,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 107,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 108,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 109,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 110,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 111,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 112,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 113,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 114,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 115,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 116,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 117,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 118,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 119,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 120,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 121,
                "component": ToggleCommonTableView
            },
        ]
    },
    {
        "subtopic": 5,
        "cards": [
            {
                "chartId": 122,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 123,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 124,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 125,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 126,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 127,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 128,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 129,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 130,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 131,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 132,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 133,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 134,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 135,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 136,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 137,
                "component": ToggleCommonTableView,//ToggleTableView
            },
            {
                "chartId": 138,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 139,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 140,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 141,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 142,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 143,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 144,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 145,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 146,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 147,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 148,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 149,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 150,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 151,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 152,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 153,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 154,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 155,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 156,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 157,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 158,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 159,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 160,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 161,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 162,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 163,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 164,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 165,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 166,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 167,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 168,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 169,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 170,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 171,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 172,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 173,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 174,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 175,
                "component": ToggleCommonTableView
            },
        ]
    },
    {
        "subtopic": 6,
        "cards": [
            {
                "chartId": 182,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 183,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 184,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 185,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 186,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 187,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 188,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 189,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 190,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 191,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 192,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 193,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 195,
                "component": ToggleCommonTableView
            },
        ]
    },
    {
        "subtopic": 7,
        "cards": [
            {
                "chartId": 212,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 213,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 214,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 215,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 217,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 218,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 219,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 220,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 221,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 223,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 225,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 226,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 227,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 228,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 229,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 230,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 231,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 232,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 233,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 234,
                "component": ToggleCommonTableView
            },
        ]
    },
    {
        "subtopic": 8,
        "cards": [
            {
                "chartId": 242,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 246,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 247,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 248,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 249,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 251,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 252,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 253,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 254,
                "component": ToggleCommonTableView
            },
        ]
    },
    {
        "subtopic": 9,
        "cards": [
            {
                "chartId": 257,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 258,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 259,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 260,
                "component": ToggleCommonTableView
            },
        ]
    },
    {
        "subtopic": 10,
        "cards": [
            {
                "chartId": 262,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 263,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 264,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 265,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 336,
                "component": ToggleCommonTableView
            },
        ]
    },
    {
        "subtopic": 12,
        "cards": [
            {
                "chartId": 285,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 287,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 289,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 293,
                "component": ToggleCommonTableView
            },
        ]
    },
    {
        "subtopic": 11,
        "cards": [
            {
                "chartId": 272,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 277,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 278,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 280,
                "component": ToggleCommonTableView
            },
            // {
            //     "chartId": 281,
            //     "component": ToggleCommonTableView
            // },
            // {
            //     "chartId": 282,
            //     "component": ToggleCommonTableView
            // },
            // {
            //     "chartId": 283,
            //     "component": ToggleCommonTableView
            // },
        ]
    },
    {
        "subtopic": 14,
        "cards": [
            {
                "chartId": 310,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 311,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 312,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 313,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 314,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 315,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 320,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 321,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 322,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 323,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 324,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 325,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 328,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 330,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 331,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 332,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 333,
                "component": ToggleCommonTableView
            },
        ]
    },
    {
        "subtopic": 16,
        "cards": [
            {
                "chartId": 353,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 354,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 355,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 356,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 357,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 359,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 361,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 362,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 363,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 364,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 664,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 365,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 366,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 665,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 367,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 370,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 373,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 374,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 375,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 376,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 377,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 378,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 379,
                "component": ToggleCommonTableView
            },
        ]
    },
    {
        "subtopic": 15,
        "cards": [
            {
                "chartId": 337,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 338,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 339,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 340,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 341,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 342,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 343,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 344,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 345,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 346,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 347,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 348,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 349,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 350,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 351,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 380,
                "component": ToggleCommonTableView
            },
        ]
    },
    {
        "subtopic": 17,
        "cards": [
            {
                "chartId": 394,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 395,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 396,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 397,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 398,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 399,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 400,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 401,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 402,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 403,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 404,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 405,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 406,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 407,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 408,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 409,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 410,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 410,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 411,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 412,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 413,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 414,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 415,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 416,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 417,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 418,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 419,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 420,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 421,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 422,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 423,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 424,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 425,
                "component": ToggleCommonTableView
            },

        ]
    },
    {
        "subtopic": 19,
        "cards": [
            {
                "chartId": 433,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 438,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 439,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 480,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 481,
                "component": ToggleCommonTableView
            },
        ]
    },
    {
        "subtopic": 21,
        "cards": [
            {
                "chartId": 484,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 485,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 486,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 487,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 488,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 489,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 491,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 492,
                "component": ToggleCommonTableView
            },
        ]
    },
    {
        "subtopic": 22,
        "cards": [
            {
                "chartId": 494,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 495,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 497,
                "component": ToggleCommonTableView
            },
        ]
    },
    {
        "subtopic": 33,
        "cards": [
            {
                "chartId": 532,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 533,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 534,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 536,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 537,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 538,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 541,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 542,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 543,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 544,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 545,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 546,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 547,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 548,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 549,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 551,
                "component": ToggleCommonTableView
            },
        ]
    },
    {
        "subtopic": 34,
        "cards": [
            {
                "chartId": 552,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 553,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 554,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 557,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 558,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 559,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 560,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 561,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 562,
                "component": ToggleCommonTableView
            },
        ]
    },
    {
        "subtopic": 35,
        "cards": [
            {
                "chartId": 563,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 564,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 565,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 566,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 567,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 568,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 569,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 570,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 571,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 572,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 573,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 574,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 575,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 576,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 577,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 578,
                "component": ToggleCommonTableView
            },
        ]
    },
    {
        "subtopic": 36,
        "cards": [
            {
                "chartId": 579,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 580,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 581,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 582,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 583,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 584,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 585,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 586,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 587,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 588,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 589,
                "component": ToggleCommonTableView
            },
        ]
    },
    {
        "subtopic": 37,
        "cards": [
            {
                "chartId": 603,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 597,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 598,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 599,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 600,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 601,
                "component": ToggleCommonTableView
            },
            {
                "chartId": 602,
                "component": ToggleCommonTableView
            },
        ]
    },
    {
        "subtopic": 38,
        "cards": [
            {
                "chartId": 604,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 605,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 606,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 607,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 608,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 609,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 610,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 611,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 613,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 614,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 615,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 616,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 617,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 618,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 619,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 620,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 621,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 622,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 623,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 624,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 625,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 626,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 627,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 628,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 629,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 630,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 631,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 632,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 633,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 634,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 635,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 636,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 637,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 638,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 639,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 640,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 641,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 642,
                "component": ToggleCommonTableView,
            },
        ]
    },
    {
        "subtopic": 39,
        "cards": [
            {
                "chartId": 643,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 644,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 645,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 646,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 647,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 648,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 649,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 651,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 652,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 653,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 654,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 655,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 656,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 657,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 658,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 659,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 660,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 661,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 662,
                "component": ToggleCommonTableView,
            },
            {
                "chartId": 663,
                "component": ToggleCommonTableView,
            },
        ]
    },
]