// chart color based on chart id mapping
export const defaultChartColor = [
    "#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#87c1e9",
    "#4f87af", "#1f425b",
    "#8095a3", "#8daabd", "#5b8cad", "#6aa4cb", "#2775a9", "#0b6cad"]//["#78c6cd", "#4F84AA", "#39576C", "#73AAD1", "#A6C9E2", "#cde9ec"];
export const ChartColors = {
    1: {
        0: ["#78c6cd", "#39576C", "#a887e0"]
    },
    2: {
        1: ["#39576C", "#4F84AA", "#73AAD1"],
        2: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#acce5a", "#87c1e9", "#4f87af", "#1f425b"],
    },
    3: {
        1: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#acce5a", "#87c1e9", "#4f87af", "#1f425b"],
        2: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#acce5a", "#87c1e9", "#4f87af", "#1f425b"],
    },
    4: {
        1: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#acce5a", "#87c1e9", "#4f87af", "#1f425b"],
        2: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#acce5a", "#87c1e9", "#4f87af", "#1f425b"],
    },
    5: {
        1: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#acce5a", "#87c1e9", "#4f87af", "#1f425b"],
        2: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#acce5a", "#87c1e9", "#4f87af", "#1f425b"],
    },
    6: {
        1: ["#3879a8"],
    },
    7: {
        1: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    8: {
        1: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#acce5a", "#87c1e9", "#4f87af", "#1f425b"],
        2: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#acce5a", "#87c1e9", "#4f87af", "#1f425b"],
    },
    9: {
        1: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#acce5a", "#87c1e9", "#4f87af", "#1f425b"],
        2: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#acce5a", "#87c1e9", "#4f87af", "#1f425b"],
    },
    10: {
        1: ["#3879a8"],
    },
    16: {
        1: ["#3879a8"],
    },
    20: {
        1: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#acce5a", "#87c1e9", "#4f87af", "#1f425b"],
        2: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#acce5a", "#87c1e9", "#4f87af", "#1f425b"],
    },
    21: {
        2: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        3: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    22: {
        4: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        5: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    23: {
        1: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#acce5a", "#87c1e9", "#4f87af", "#1f425b"],
    },
    24: ["#39576C", "#4F84AA"],
    30: {
        2: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        3: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    31: {
        4: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        5: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    32: {
        2: ["#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        3: ["#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    33: {
        4: ["#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        5: ["#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    34: {
        2: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        4: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    35: {
        2: ["#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        3: ["#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    36: {
        4: ["#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        5: ["#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    11: {
        1: ["#39576C", "#4F84AA"],
    },
    13: {
        1: ["#39576C", "#4F84AA"],
    },
    12: {
        2: ["#5322a7"],
        3: ["#798F44"],
        4: ["#5322a7"],
        5: ["#798F44"],
    },
    14: {
        2: ["#5322a7", "#798F44"],
        3: ["#798F44"],
        4: ["#5322a7", "#798F44"],
        5: ["#798F44",]
    },
    39: {
        3: ["#39576C", "#4F84AA"],
    },
    40: {
        3: ["#39576C", "#4F84AA"],
    },
    41: {
        3: ["#39576C", "#4F84AA"],
    },
    53: {
        3: ["#78c6cd", "#4F84AA", "#39576C", "#73AAD1", "#A6C9E2", "#cde9ec"],
    },
    59: {
        3: ["#39576C", "#4F84AA"],
    },
    60: {
        3: ["#39576C", "#4F84AA"],
    },
    61: {
        3: ["#39576C", "#4F84AA"],
    },
    42: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"]
    },
    43: {
        6: ["#250162", "#5322a7"],
        7: ["#250162", "#5322a7"],
    },
    44: ["#78c6cd", "#4F84AA", "#39576C"],
    46: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        // 7: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    52: ["#78c6cd", "#4F84AA", "#39576C", "#73AAD1", "#A6C9E2", "#cde9ec"],
    47: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1", "#998bb1"],
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1", "#998bb1"],
    },
    54: {
        8: ["#78c6cd", "#4F84AA", "#39576C", "#73AAD1", "#A6C9E2", "#cde9ec"],
        9: ["#78c6cd", "#4F84AA", "#39576C", "#73AAD1", "#A6C9E2", "#cde9ec"],
        10: ["#78c6cd", "#4F84AA", "#39576C", "#73AAD1", "#A6C9E2", "#cde9ec"],
        11: ["#78c6cd", "#4F84AA", "#39576C", "#73AAD1", "#A6C9E2", "#cde9ec"],
        12: ["#78c6cd", "#4F84AA", "#39576C", "#73AAD1", "#A6C9E2", "#cde9ec"],
        13: ["#78c6cd", "#4F84AA", "#39576C", "#73AAD1", "#A6C9E2", "#cde9ec"],
    },
    55: {
        3: ["#3879a8"],
    },
    57: {
        3: ["#39576C", "#4F84AA"]
    },
    62: ["#78c6cd", "#4F84AA", "#39576C", "#73AAD1", "#A6C9E2", "#cde9ec"],
    // 62: {
    //     14: ["#78c6cd", "#4F84AA", "#39576C", "#73AAD1", "#A6C9E2", "#cde9ec"],
    //     15: ["#78c6cd", "#4F84AA", "#39576C", "#73AAD1", "#A6C9E2", "#cde9ec"],
    //     16: ["#78c6cd", "#4F84AA", "#39576C", "#73AAD1", "#A6C9E2", "#cde9ec"],
    //     17: ["#78c6cd", "#4F84AA", "#39576C", "#73AAD1", "#A6C9E2", "#cde9ec"],
    //     18: ["#78c6cd", "#4F84AA", "#39576C", "#73AAD1", "#A6C9E2", "#cde9ec"],
    // },
    64: {
        3: ["#39576C", "#4F84AA"],
    },
    65: {
        3: ["#39576C", "#4F84AA"],
    },
    66: {
        3: ["#39576C", "#4F84AA"],
    },
    67: {
        3: ["#39576C", "#4F84AA"],
    },
    //medical Exeption
    68: {
        3: ["#3879a8"],
    },
    69: {
        3: ["#3879a8"],
    },
    70: {
        3: ["#39576C", "#4F84AA"],
    },
    71: {
        3: ["#3879a8"],
    },
    72: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    73: {
        3: ["#3879a8"],
    },
    74: {
        3: ["#3879a8"],
    },
    75: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    76: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    77: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    78: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    79: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    80: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    83: {
        19: ["#3879a8"],
        20: ["#3879a8"],
    },
    84: {
        19: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        20: ["#250162", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    85: {
        19: ["#464F2F", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        20: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    86: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    87: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    88: {
        3: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#acce5a", "#87c1e9", "#4f87af", "#1f425b"],
    },
    89: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    90: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    91: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    92: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    93: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    94: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    95: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    96: ["#78c6cd", "#4F84AA", "#39576C", "#73AAD1", "#A6C9E2", "#cde9ec"],
    97: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    98: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    99: {
        3: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#87c1e9", "#4f87af", "#1f425b"],
    },
    100: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },

    101: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    102: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    103: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    104: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    105: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    106: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    107: { //toggle plainbar
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },

    108: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    109: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },

    110: { //toggle plainbar
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    111: { //toggle plainbar
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    112: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    113: { //toggle plainbar
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    114: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    115: { //toggle plainbar
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    116: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    117: { //6-purple (Physician/oncology) 21-green (practice manager)
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    118: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    119: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    120: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    121: { //6-purple (Physician/oncology) 21-green (practice manager)
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    // medical EX OII
    122: {
        3: ["#3879a8"],
    },
    123: {
        3: ["#3879a8"],
    },
    124: {
        3: ["#39576C", "#4F84AA"],
    },
    125: {
        3: ["#3879a8"],
    },
    126: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    127: {
        3: ["#3879a8"],
    },
    128: {
        3: ["#3879a8"],
    },
    129: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    130: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    131: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    132: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    133: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    134: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    137: {
        19: ["#3879a8"],
        20: ["#3879a8"],
    },
    138: {
        19: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        20: ["#250162", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    139: {
        19: ["#464F2F", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        20: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    140: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    141: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    142: {
        3: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#acce5a", "#87c1e9", "#4f87af", "#1f425b"],
    },
    143: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    144: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    145: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    146: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    147: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    148: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    149: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    150: ["#78c6cd", "#4F84AA", "#39576C", "#73AAD1", "#A6C9E2", "#cde9ec"],
    151: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    152: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    153: {
        3: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#87c1e9", "#4f87af", "#1f425b"],
    },
    154: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    155: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1", "#998bb1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    156: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    157: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1", "#998bb1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF", "#c7d2ad"],
    },
    158: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    159: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    160: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    161: { //toggle plainbar
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    162: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    163: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    164: { //toggle plainbar
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    165: { //toggle plainbar
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    166: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    167: { //toggle plainbar
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    168: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    169: { //toggle plainbar
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    170: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    171: { //6-purple (Physician/oncology) 21-green (practice manager)
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    172: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    173: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    174: {
        3: ["#39576C", "#4F84AA", "#73AAD1"],
    },
    175: { //6-purple (Physician/oncology) 21-green (practice manager)
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },

    //Bar stack graph PPR
    182: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#87c1e9", "#4f87af", "#1f425b"],
    183: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#87c1e9", "#4f87af", "#1f425b"],
    184: {
        3: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#87c1e9", "#4f87af", "#1f425b"],
    },
    //IRA:
    238: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    239: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],

    //simple bar graph
    185: {
        3: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#87c1e9", "#4f87af", "#1f425b"],
    },
    186: {
        3: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#87c1e9", "#4f87af", "#1f425b"],
    },
    187: {
        3: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#87c1e9", "#4f87af", "#1f425b"],
    },
    189: {
        3: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#87c1e9", "#4f87af", "#1f425b"],
    },
    191: {
        3: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#87c1e9", "#4f87af", "#1f425b"],
    },
    192: {
        3: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#87c1e9", "#4f87af", "#1f425b"],
    },
    195: {
        3: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#87c1e9", "#4f87af", "#1f425b"],
    },

    //single stack graph 
    // 39: {
    //     3: ["#39576C", "#4F84AA"],
    // },
    188: {
        3: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#87c1e9", "#4f87af", "#1f425b"],
    },
    190: {
        3: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#87c1e9", "#4f87af", "#1f425b"],
    },
    193: {
        3: ["#39576C", "#4F84AA", "#73AAD1", "#A6C9E2", "#D9E8F2", "#87c1e9", "#4f87af", "#1f425b"],
    },

    //singlebar chart with physician and practice Manager
    235: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    236: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    237: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    244: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    245: {
        21: ["#464F2F", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    250: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    255: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    256: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    //Pre-treatment (PPTQ)
    294: { //6-purple (Physician/oncology) 21-green (practice manager)
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    296: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    298: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    299: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    300: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    301: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    302: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    303: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    304: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1", "#dbd3e8"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF", "#c7d2ad"],
    },
    305: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1", "#dbd3e8"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF", "#c7d2ad"],
    },
    306: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1", "#dbd3e8"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF", "#c7d2ad"],
    },
    307: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1", "#dbd3e8"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF", "#c7d2ad"],
    },
    308: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1", "#dbd3e8"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF", "#c7d2ad"],
    },
    309: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    //AB
    286: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    293: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    282: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    283: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    316: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    317: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    318: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    319: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    329: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    330: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    331: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    334: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    335: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    278: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1", "#998bb1"],
    },
    407: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        // 7: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    409: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    410: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    416: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    417: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    418: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    419: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    433: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    438: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    475: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    477: {
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    480: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    481: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C", "#DFECBF"],
    },
    //PRSP
    430: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C",
            "#d7ee9f", "#9faf79", "#d3ee91", "#DFECBF",
        ],
    },
    442: {
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C",
            "#d7ee9f", "#9faf79", "#d3ee91", "#DFECBF",
        ],
    },
    443: {
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C",
            "#d7ee9f", "#9faf79", "#d3ee91", "#DFECBF",
        ],
    },
    449: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C",
            "#d7ee9f", "#9faf79", "#d3ee91", "#DFECBF",
        ],
    },
    450: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C",
            "#d7ee9f", "#9faf79", "#d3ee91", "#DFECBF",
        ],
    },
    456: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C",
            "#d7ee9f", "#9faf79", "#d3ee91", "#DFECBF",
        ],
    },
    457: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C",
            "#d7ee9f", "#9faf79", "#d3ee91", "#DFECBF",
        ],
    },
    458: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C",
            "#d7ee9f", "#9faf79", "#d3ee91", "#DFECBF",
        ],
    },
    459: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    460: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    461: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    462: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    463: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    464: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    465: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    466: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
    },
    467: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C",
            "#d7ee9f", "#9faf79", "#d3ee91", "#DFECBF",
        ],
    },
    468: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C"],
    },
    469: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C"],
    },
    470: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C"],
    },
    471: {
        6: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C"],
    },
    472: {
        7: ["#250162", "#5322a7", "#8351d9", "#a887e0", "#cbb5f1"],
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C"],
    },
    435: {
        21: ["#464F2F", "#798F44", "#ACCE5A", "#C5DD8C",
            "#d7ee9f", "#9faf79", "#d3ee91", "#DFECBF",
        ],
    }
}

// chart alignment based on chart id mapping
// export const defaultStackAlign = { top: -40, bottom: 30, height: 130 }
export const defaultStackAlign = { top: 40, bottom: 20, height: 200 }
export const defaultBarAlign = { top: 20, bottom: 30, height: 350 }
export const defaultBarStackAlign = { top: 20, bottom: 30, height: 500 }
export const defaultColumnChartAlign = { top: 100, bottom: 90, height: 550 }

export const ChartAlgin = {
    1: {
        0: { top: 20, bottom: 30, height: 300 },
    },
    2: {
        1: { top: 40, bottom: 20, height: 200 },
        2: { top: 40, bottom: 20, height: 200 },
    },
    3: {
        1: { top: 40, bottom: 20, height: 200 },
        2: { top: 40, bottom: 20, height: 200 },
    },
    4: {
        1: { top: 40, bottom: 20, height: 200 },
        2: { top: 40, bottom: 20, height: 200 },
    },
    5: {
        1: { top: 40, bottom: 20, height: 200 },
        2: { top: 40, bottom: 20, height: 200 },
    },
    8: {
        1: { top: 40, bottom: 20, height: 200 },
        2: { top: 40, bottom: 20, height: 200 },
    },
    9: {
        1: { top: 40, bottom: 20, height: 200 },
        2: { top: 40, bottom: 20, height: 200 },
    },
    7: {
        1: { top: 40, bottom: 20, height: 200 },
    },
    6: { top: 20, bottom: 30, height: 300 },
    10: { top: 20, bottom: 30, height: 400 },
    16: { top: 20, bottom: 30, height: 200 },
    20: {
        1: { top: 40, bottom: 20, height: 200 },
        2: { top: 40, bottom: 20, height: 200 }
    },
    21: {
        2: { top: 40, bottom: 20, height: 200 },
        3: { top: 40, bottom: 20, height: 200 },
    },
    22: {
        4: { top: 40, bottom: 20, height: 200 },
        5: { top: 40, bottom: 20, height: 200 },
    },
    23: {
        1: { top: 40, bottom: 20, height: 200 },
    },
    24: { top: 40, bottom: 20, height: 200 },
    30: {
        2: { top: 40, bottom: 20, height: 200 },
        3: { top: 40, bottom: 20, height: 200 },
    },
    31: {
        4: { top: 40, bottom: 20, height: 200 },
        5: { top: 40, bottom: 20, height: 200 },
    },
    32: {
        2: { top: 40, bottom: 20, height: 200 },
        3: { top: 40, bottom: 20, height: 200 },
    },
    33: {
        4: { top: 40, bottom: 20, height: 200 },
        5: { top: 40, bottom: 20, height: 200 },
    },
    34: {
        2: { top: 40, bottom: 20, height: 200 },
        4: { top: 40, bottom: 20, height: 200 },
    },
    35: {
        2: { top: 40, bottom: 20, height: 200 },
        3: { top: 40, bottom: 20, height: 200 },
    },
    36: {
        4: { top: 40, bottom: 20, height: 200 },
        5: { top: 40, bottom: 20, height: 200 },
    },
    39: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    11: { top: 20, bottom: 30, height: 350 },
    13: { top: 20, bottom: 30, height: 350 },
    40: { top: 20, bottom: 30, height: 350 },
    41: { top: 20, bottom: 30, height: 350 },
    42: {
        6: { top: 20, bottom: 30, height: 500 },
        7: { top: 20, bottom: 30, height: 500 }
    },
    43: {
        6: { top: 20, bottom: 30, height: 300 },
        7: { top: 20, bottom: 30, height: 300 }
    },
    44: {
        3: { top: 20, bottom: 20, height: 800 }
    },
    46: {
        6: { top: 20, bottom: 30, height: 350 },
        7: { top: 20, bottom: 30, height: 500 }
    },
    47: {
        6: { top: 80, bottom: 10, height: 600 },
        7: { top: 80, bottom: 10, height: 1100 }
    },
    52: {
        3: { top: 20, bottom: 30, height: 500 }
    },
    53: { top: 70, bottom: 0, height: 1680 },
    54: {
        8: { top: 50, bottom: 10, height: 600 },
        9: { top: 50, bottom: 10, height: 350 },
        10: { top: 50, bottom: 10, height: 600 },
        11: { top: 50, bottom: 10, height: 600 },
        12: { top: 50, bottom: 10, height: 400 },
        13: { top: 50, bottom: 10, height: 350 },
    },

    57: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    59: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    62: {
        34: { top: 30, bottom: 10, height: 200 },
        35: { top: 30, bottom: 10, height: 200 },
        36: { top: 30, bottom: 10, height: 200 },
        37: { top: 30, bottom: 10, height: 120 },
        38: { top: 30, bottom: 10, height: 120 },

        39: { top: 30, bottom: 10, height: 1000 },
        40: { top: 30, bottom: 10, height: 750 },

        41: { top: 30, bottom: 10, height: 500 },
        42: { top: 30, bottom: 10, height: 300 },
        43: { top: 30, bottom: 10, height: 300 },

        44: { top: 30, bottom: 10, height: 350 },
        45: { top: 30, bottom: 10, height: 300 },
        46: { top: 30, bottom: 10, height: 120 },
    },
    //medical exception
    68: { top: 20, bottom: 30, height: 300 },
    69: { top: 20, bottom: 30, height: 300 },
    // 70: {
    //     3: { top: 40, bottom: 20, height: 200 },
    // },
    71: { top: 20, bottom: 30, height: 300 },
    72: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    73: { top: 20, bottom: 30, height: 300 },
    74: { top: 20, bottom: 30, height: 300 },
    75: {
        6: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },
    76: {
        6: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },
    77: {
        6: { top: 10, bottom: 10, height: 400 },
        21: { top: 10, bottom: 10, height: 400 },
    },
    78: { top: 20, bottom: 30, height: 350 },
    79: { top: 20, bottom: 30, height: 350 },
    80: {
        6: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },
    83: {
        19: { top: 10, bottom: 10, height: 350 },
        20: { top: 10, bottom: 10, height: 350 },
    },
    84: {
        19: { top: 10, bottom: 10, height: 400 },
        20: { top: 10, bottom: 10, height: 400 },
    },
    85: {
        19: { top: 10, bottom: 10, height: 400 },
        20: { top: 10, bottom: 10, height: 400 },
    },
    86: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    87: { top: 20, bottom: 30, height: 200 },
    88: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    89: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    90: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    91: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    92: {
        6: { top: 20, bottom: 30, height: 350 },
        21: { top: 20, bottom: 30, height: 350 },
    },
    93: { top: 20, bottom: 30, height: 200 },
    94: { top: 20, bottom: 30, height: 200 },
    95: { top: 20, bottom: 30, height: 200 },
    96: {
        3: { top: 20, bottom: 30, height: 350 },
    },
    97: {
        6: { top: 20, bottom: 30, height: 350 },
    },
    98: {
        21: { top: 20, bottom: 30, height: 350 },
    },
    99: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    100: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    101: {
        6: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },
    102: {
        6: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },
    103: {
        6: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },
    104: {
        6: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },
    105: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    106: {
        6: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },
    107: {
        6: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },
    108: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    109: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    110: {
        6: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },
    111: {
        6: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },
    112: { //meanstack
        6: { top: 40, bottom: 20, height: 200 },
    },
    113: { //meanstack
        21: { top: 40, bottom: 20, height: 200 },
    },
    114: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    115: { //toggle plain bar
        6: { top: 10, bottom: 10, height: 350 },
        21: { top: 10, bottom: 10, height: 350 },
    },
    116: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    117: { //stack mean
        6: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },
    118: { top: 20, bottom: 30, height: 250 }, //plain bar
    119: { //toggle plain bar
        6: { top: 10, bottom: 10, height: 350 },
        21: { top: 10, bottom: 10, height: 350 },
    },
    120: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    121: { //stack mean
        6: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },

    // medical Ex OII
    122: { top: 20, bottom: 30, height: 300 },
    123: { top: 20, bottom: 30, height: 300 },
    124: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    125: { top: 20, bottom: 30, height: 300 },
    126: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    127: { top: 20, bottom: 30, height: 300 },
    128: { top: 20, bottom: 30, height: 300 },
    129: {
        7: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },
    130: {
        7: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },
    131: {
        7: { top: 10, bottom: 10, height: 400 },
        21: { top: 10, bottom: 10, height: 400 },
    },
    132: {
        7: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },
    133: {
        7: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },
    134: {
        7: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },

    137: {
        19: { top: 10, bottom: 10, height: 350 },
        20: { top: 10, bottom: 10, height: 350 },
    },
    138: {
        19: { top: 10, bottom: 10, height: 400 },
        20: { top: 10, bottom: 10, height: 400 },
    },
    139: {
        19: { top: 10, bottom: 10, height: 400 },
        20: { top: 10, bottom: 10, height: 400 },
    },
    140: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    141: { top: 20, bottom: 30, height: 200 },
    142: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    143: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    144: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    145: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    146: {
        7: { top: 20, bottom: 30, height: 350 },
        21: { top: 20, bottom: 30, height: 350 },
    },
    147: { top: 20, bottom: 30, height: 200 },
    148: {
        7: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },
    149: {
        7: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },
    150: {
        3: { top: 20, bottom: 30, height: 350 },
    },
    151: {
        7: { top: 20, bottom: 30, height: 350 },
    },
    152: {
        21: { top: 20, bottom: 30, height: 350 },
    },
    153: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    154: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    155: {
        7: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },
    156: {
        7: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },
    157: {
        7: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },
    158: {
        7: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },
    159: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    160: {
        7: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },
    161: {
        7: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },
    162: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    163: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    164: {
        7: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },
    165: {
        7: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },
    166: { //meanstack
        7: { top: 40, bottom: 20, height: 200 },
    },
    167: { //meanstack
        21: { top: 40, bottom: 20, height: 200 },
    },
    168: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    169: { //toggle plain bar
        7: { top: 10, bottom: 10, height: 350 },
        21: { top: 10, bottom: 10, height: 350 },
    },
    170: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    171: { //stack mean
        7: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },
    172: { top: 20, bottom: 30, height: 250 }, //plain bar
    173: { //toggle plain bar
        7: { top: 10, bottom: 10, height: 150 },
        21: { top: 10, bottom: 10, height: 150 },
    },
    174: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    175: { //stack mean
        7: { top: 40, bottom: 20, height: 200 },
        21: { top: 40, bottom: 20, height: 200 },
    },

    188: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    190: {
        3: { top: 40, bottom: 20, height: 200 },
    },
    //Single bar chart
    191: { top: 20, bottom: 10, height: 1200 },
    601: { top: 20, bottom: 10, height: 1200 },
    221: { top: 20, bottom: 10, height: 1100 },
    223: { top: 20, bottom: 10, height: 1100 },
    228: { top: 20, bottom: 10, height: 1300 },
    229: { top: 20, bottom: 10, height: 1300 },
    //DA
    242: { top: 20, bottom: 10, height: 1300 },
    244: { top: 20, bottom: 10, height: 1300 },
    245: { top: 20, bottom: 10, height: 1300 },
    248: { top: 130, bottom: 10, height: 5500 },
    249: { top: 20, bottom: 10, height: 1200 },
    250: { top: 20, bottom: 10, height: 1000 },
    252: { top: 130, bottom: 10, height: 5500 },
    258: { top: 20, bottom: 10, height: 1500 },
    259: {
        14: { top: 100, bottom: 10, height: 300 },
        15: { top: 100, bottom: 10, height: 300 },
        26: { top: 100, bottom: 10, height: 300 },
    },
    260: { top: 20, bottom: 10, height: 1500 },
    195: { top: 20, bottom: 10, height: 500 },
    //IDC
    262: { top: 20, bottom: 10, height: 1500 },
    280: { top: 30, bottom: 10, height: 600 },
    287: { top: 20, bottom: 10, height: 1500 },
    289: { top: 20, bottom: 10, height: 1500 },
    278: { top: 40, bottom: 10, height: 800 },
    338: { top: 60, bottom: 10, height: 900 },
    340: { top: 70, bottom: 10, height: 600 },
    343: {
        3: { top: 40, bottom: 10, height: 1000 },
    },
    349: { top: 70, bottom: 10, height: 900 },
    354: { top: 20, bottom: 10, height: 1500 },
    405: { top: 100, bottom: 10, height: 1000 },
    406: { top: 100, bottom: 10, height: 1000 },
    456: {
        7: { top: 20, bottom: 10, height: 1000 },
        21: { top: 20, bottom: 10, height: 1000 },
    },

    //Bar Stack graph
    238: {
        6: { top: 20, bottom: 30, height: 300 },
        7: { top: 20, bottom: 30, height: 300 },
    },
    239: {
        6: { top: 20, bottom: 30, height: 300 },
        7: { top: 20, bottom: 30, height: 300 },
    },
    257: {
        3: { top: 50, bottom: 10, height: 2500 },
    },
    264: {
        3: { top: 50, bottom: 10, height: 2500 },
    },
    265: {
        3: { top: 50, bottom: 10, height: 700 },
    },
    182: {
        3: { top: 50, bottom: 10, height: 700 },
    },
    183: {
        3: { top: 50, bottom: 10, height: 700 },
    },
    336: {
        3: { top: 50, bottom: 10, height: 700 },
    },
    325: {
        3: { top: 50, bottom: 10, height: 2000 },
    },
    328: {
        3: { top: 30, bottom: 10, height: 200 },
    },
    329: {
        7: { top: 30, bottom: 10, height: 200 },
    },
    //PPTQ
    294: {
        6: { top: 30, bottom: 20, height: 280 },
        21: { top: 30, bottom: 20, height: 280 },
    },
    299: {
        6: { top: 30, bottom: 20, height: 280 },
        21: { top: 30, bottom: 20, height: 280 },
    },
    302: {
        6: { top: 40, bottom: 20, height: 600 },
        21: { top: 40, bottom: 20, height: 600 },
    },
    303: {
        6: { top: 40, bottom: 20, height: 600 },
        21: { top: 40, bottom: 20, height: 600 },
    },
    395: {
        3: { top: 50, bottom: 20, height: 700 },
    },
    396: {
        3: { top: 50, bottom: 20, height: 700 },
    },
    398: {
        3: { top: 50, bottom: 20, height: 700 },
    },
    399: {
        3: { top: 60, bottom: 20, height: 700 },
    },
    364: {
        27: { top: 80, bottom: 20, height: 500 },
        28: { top: 80, bottom: 20, height: 500 },
    },
    664: {
        27: { top: 80, bottom: 20, height: 500 },
        28: { top: 80, bottom: 20, height: 500 },
    },
    365: {
        3: { top: 50, bottom: 20, height: 500 },
    },
    230: {
        3: { top: 50, bottom: 20, height: 800 },
    },
    505: {
        21: { top: 40, bottom: 20, height: 2000 },
    },
    506: {
        21: { top: 40, bottom: 20, height: 700 },
    },
    507: {
        21: { top: 40, bottom: 20, height: 700 },
    },
    493: {
        6: { top: 60, bottom: 20, height: 800 },
    },
    503: { top: 40, bottom: 20, height: 600 },

    //single stackbar graph
    430: { //meanstack
        21: { top: 100, bottom: 20, height: 320 },
    },
    442: { //meanstack
        21: { top: 100, bottom: 20, height: 320 },
    },
    376: { //meanstack
        3: { top: 80, bottom: 20, height: 230 },
    },
    377: { //meanstack
        3: { top: 60, bottom: 20, height: 210 },
    },

}

export const CardLayout = {
    1: "col-md-12 col-sm-12 col-12",
    2: "col-md-6 col-sm-12 col-12 border-right",
    3: "col-md-6 col-sm-12 col-12 border-right",
    4: "col-md-12 col-sm-12 col-12 border-right"
}

// PayerFootnote enable and disable based on chart id mapping
export const PayerFootnote = [
    // 6, 7, 10,//copay
    // 11, 13, 16, 17, 20, 23, 24,//PAP
    // 62,//biosimilar
]

// PayerVerbFootnote enable and disable based on chart id mapping
export const PayerVerbFootnote = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, //Copay
    11, 13, 16, 20, 23, //PAP
    39, 40, 44, 52, 53, 54, 55, 57, 59, 60, 61, 62, ////Bio similar
    64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 83, 87, 88, 86, 89, 90, 91, 93,
    96, 99, 100, 105, 108, 109, 114, 116, 118, 120, //ME BII
    122, 123, 125, 127, 128, 124, 126, 140, 141, 142, 137, 143, 144, 145,
    147, 150, 153, 154, 159, 162, 163, 168, 170, 172, 174, //ME OII
    182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 195, // PRR
    212, 213, 214, 215, 217, 218, 219, 220, 221, 223, 225,
    226, 227, 228, 229, 230, 231, 232, 233, 234,//IRA
    242, 246, 247, 248, 249, 251, 252, 253, 254, //DA
    257, 258, 259, 260, //PMP
    262, 263, 264, 265, 336, //IDC
    //PPTQ
    285, 286, 287, 289, //AB
    272, 277, 279, 280, 281, //SC
    310, 311, 312, 313, 314, 315, 320, 321, 322, 323,
    324, 325, 328, 330, 331, 332, 333,//PTC
    353, 354, 355, 356, 357, 359, 361, 362, 363, 364, 365, 366, 367, 370, 373, 664, 665,
    374, 375, 376, 377, 378, 379, //SSP,PPM
    337, 338, 339, 340, 341, 342, 343, 344, 345, 346, 347, 348, 349, 350, 351,
    380,//JK
    394, 395, 396, 397, 398, 399, 400, 401, 402, 403, 404, 405, 406, 408,
    409, 410, 413, 414, 415, 420, 421, 422,
    424, 425, //MBT
    433, 438, 439, 480, 481, //ChartGPT
    484, 485, 486, 487, 488, 489, 491, 492, 494, 495, 497,//DD HAE
    532, 533, 534, 536, 538, 541, 542, 543, 544, 545, 546, 547, 548, 549, 551,
    552, 553, 554, 556, 557, 558, 559, 560, 561, 562,
    563, 564, 565, 566, 567, 568, 569, 570, 571, 572, 573, 574, 575, 576, 577, 578,
    579, 580, 581, 583, 584, 585, 586, 587, 588, 589,
    603, 597, 598, 599, 600, 601, 602,
    604, 605, 606, 607, 608, 609, 610, 611, 612, 621, 622, 623, 624, 628, 629, 630, 631, 632, 633, 634, 638, 639, 640,
    643, 644, 645, 646, 648, 651, 652, 653, 654, 655, 660,
]

// Mean placeholder value enable and disable based on chart id mapping
export const MeanArea = [
    20, 21, 22, 30, 31, 34, 90, 91, 75, 76, 80, 88, 92, 110, 117, 120, 121,
    129, 130, 134, 142, 144, 145, 146, 164, 171, 174, 175,
    212, 214, 215, 226, 233, 235, 236, 237, 238, 239,//IRA
    337, 339, 342,
    409, 410, //MBT
    433, 480, 532, 536, 541, 542, 547, 549,
    555, 582,
    604, 605, 609, 610, 611, 624, 625, 633, 635, 638, 640, 641, 
    652, 654, 655, 
]

// subHArea enable and disable based on chart id mapping
export const subHArea = [
    6, 7, 10,
    11, 13, 16, 17, 20, 23, 24,//PAP
    21, 22, 30, 31, 32, 33, 34, 35, 36, 39, 40, 41, 53, 55, 56, 57,
    59, 70, 86, 88,
    89, 90, 91, 96, 99, 100, 105, 108, 109, 116, 120, 72,
    113, 112, 111, 75, 76, 80, 92, 101, 102, 103, 104, 106, 107, 110, 111,
    117, 121, 92,
    124, 126, 129, 132, 133, 130, 134, 140, 142, 143, 144, 145, 146,
    148, 149, 153, 154, 155, 156, 157,
    158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 170, 171, 174, 175,
    188, 190, 193, 195, 184,//PRR
    185, 186, 187, 189, 191, 192, //PRR
    212, 213, 214, 215, 220, 226, 233, 235, 236, 237, 238, 239, //IRA
    217, 218, 221, 223, 225, 227, 228, 229, 231, 232, 234,//IRA  
    242, 243, 244, 245, 246, 248, 249, 250, 252, 253, 254, 255, 256,//DA
    258, 259, 260, //PMP
    262, 304, 309,//IDC
    285, 286, 287, 289, 290,//AB
    272, 277, 278, 279, 280, 281, 282, 283, //SC
    68, 69, 71, 73, 74, 78, 79, 87, 93, 94, 95, 114, 118, //ME BII 
    122, 123, 125, 127, 128, 141, 147, 168, 172, //ME OII
    310, 311, 312, 313, 314, 315, 316, 317, 318, 318, 319, 320, 321, 322, 323,
    324, 332, 333, 334, 335, 305, 306, 307, 308,//PTC
    353, 354, 355, 356, 357, 359, 361, 362, 363, 370, 373, 375, 376, 377, 378, 379, //SSP,PPM
    337, 338, 339, 340, 341, 342, 343, 344, 345, 346, 347, 348, 349, 350, 351,
    380,//JK    
    60, 61, 64, 65, 66, 67,//biosimilar
    394, 395, 396, 397, 398, 399, 400, 401, 402, 403, 404, 405, 406, 407, 408,
    409, 410, 411, 412, 413, 414, 415, 416, 417, 418, 419, 420, 421, 422, 423,
    424, 425, //MBT
    426, 427, 428, 429, 430, 431, 435, 442, 443, 444, 445, 446, 447,
    448, 449, 450, 451, 452, 453, 454, 455, 456, 456, 457, 457, 458,
    459, 460, 461, 462, 463, 464, 465, 466, 467, 468, 469, 470,
    471, 472,//PRSR
    433, 480,//ChartGPT
    490, 498, 502, 508, //DD HEA
    532, 533, 534, 536, 537, 538, 541, 542, 543, 544, 545, 546, 547, 548, 549, 551,
    552, 553, 554, 556, 557, 558, 559, 560, 561, 562,
    563, 564, 565, 566, 567, 568, 569, 570, 571, 572, 573, 574, 575, 576, 577, 578,
    579, 580, 581, 584, 585, 586, 587, 588, 589,
    604, 605, 606, 607, 608, 609, 610, 611, 612, 613, 614, 615, 616, 617, 618, 619, 620, 621, 622, 623, 624, 625, 626, 627, 628, 629, 630, 631, 632, 633, 634, 635, 636, 637, 638, 639, 640, 641, 642,
    643, 644, 645, 646, 647, 648, 649, 651, 652, 653, 654, 655, 656, 657, 658, 659, 660, 661, 662, 663,
]

// cardTableBtn enable and disable based on chart id mapping
export const cardTableBtn = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 15, 16, 20, 23, 24, 32,
    39, 40, 41, 44, 52, 53, 54, 55, 57, 59, 60, 61, 62,
    64, 65, 66, 67,
    68, 69, 70, 71, 72, 73, 74, 83, 86, 87, 88, 89, 90, 91, 93, 96,
    99, 100, 105, 108, 109, 114, 116, 118, 120,
    122, 123, 124, 125, 126, 127, 128, 137, 140, 141, 142, 143, 144,
    145, 147, 150, 153, 154, 159, 162, 163,
    168, 170, 172, 174, //OII
    182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 195, //PRR
    212, 213, 214, 215, 217, 218, 219, 220, 221, 223, 225,
    226, 227, 228, 229, 230, 231, 232, 233, 234,//IRA
    242, 246, 247, 248, 249, 251, 252, 253, 254,//DA
    257, 258, 259, 260, //PMP
    262, 263, 264, 265, 336,//IDC
    285, 287, 289,
    272, 277, 279, 280, //SC
    310, 311, 312, 313, 314, 315, 320, 321, 322, 323, 324, 325, 328, 330,
    331, 332, 333,//CT
    353, 354, 355, 356, 357, 359, 361, 362, 363, 364, 365, 366, 367, 370, 373, 664, 665,
    374, 375, 376, 377, 378, 379, //SSP,PPM
    337, 338, 339, 340, 341, 342, 343, 344, 345, 346, 347, 348, 349, 350, 351,
    380,//JK
    394, 395, 396, 397, 398, 399, 400, 401, 402, 403, 404, 405, 406,
    409, 410, 414, 415, 420, 421, 422,
    424, 425, //MBT 409, 410, 
    433, 438, 439, 480, 481,//ChartGPT
    484, 485, 486, 487, 488, 489, 491, 492, 494, 495, 497,//DD HAE
    532, 533, 534, 536, 537, 538, 541, 542, 543, 544, 545, 546, 547, 548, 549, 551,
    552, 553, 554, 557, 558, 559, 560, 561, 562,
    563, 564, 565, 566, 567, 568, 569, 570, 571, 572, 573, 574, 575, 576, 577, 578,
    579, 580, 581, 584, 585, 586, 587, 588, 589,
    603, 597, 598, 599, 600, 601, 602,
    604, 605, 606, 607, 608, 609, 610, 611, 621, 622, 623, 624, 628, 629, 630, 631, 632, 633, 634, 638, 639, 640,
    643, 644, 645, 646, 648, 651, 652, 653, 654, 655, 660,
]

// card download button enable and disable based on chart id mapping
export const downloadBtn = [
    38, 37, 29, 28, 25, 19, 18, 17, 45, 51, 56,
    81, 82, 135, 136, 176, 177, 178, 179, 180, 181,
    216, 222, 224, //IRA
    261, 288, 290,
    326, 327,//ctp
    352, 358, 360, 368, 369, 371, 372, //SPP
    381, 382, 384, 385, 386, 387,//ME BII
    388, 389, 390, 391, 392, 393,//ME OII
    423, 411, 412, //MBT
    434, 476, 478, 479, 482, 483, //ChartGPT
    429, 441, 473, 474,//PRSP
    556, 583
]

export const filterBtn = []

export const HeatMapBtn = []

export const textInfoBtn = [63]

// chart Legends enable and disable based on chart id mapping
export const showLegends = [
    47, 53, 54, 248, 249, 252, 259, 195, 278, 280, 338, 340, 343, 349,
    405, 406, 425,
    490, 493, 505, 506, 507 //DD HAE
]

// overlay chart event enable based on chart id mapping
export const chartOverlay = [16, 10, 6, 7, 23, 24, 123]

// toggle button default color
export const defaultToogleButtonColor = [20, 30, 32, 35]

// overlay chart event enable based on chart id mapping
export const payerPopOverlay = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, //copay
    11, 13, 16, 20, 23, //PAP
    39, 40, 41, 44, 46, 52, 53, 55, 57, 59, 60, 61, 62, 64, 65, 66, 67, //Biosimilar
    68, 69, 70, 71, 72, 73, 74, 83, 86, 87, 88, 89, 90, 91, 93, 96,
    99, 100, 105, 108, 109, 114, 115, 118, 120, //ME BII
    122, 123, 124, 125, 126, 127, 128, 137, 140, 141, 142, 143, 144,
    145, 147, 150, 153, 154, 160, 162, 163, 168, 170, 172, 174
]

// subpayer disable
export const subPayer = []

//tabular subheader enable and disable
export const tableSubHead = [62, 362, 363, 376, 377]

//category hide 
export const chartCategoryHide = []

//category hide 
export const chartHightPlotId = [62]

//barstack multicolor hide 
export const barStackMultiToggle = [
    46, 92, 146, 294, 299, 302, 303, 305, 306, 307, 308, 329,
    475, 477, 481,
]

//only without percent
export const noPercentBar = [305, 306, 307, 308, 184]

//drug separator line
export const drugSeparatorLine = [47, 53, 54]

// overlay chart event enable and disable based on chart id mapping
export const chartEvent = {
    16: ['other'],
    10: ['other'],
    6: ['other'],
    7: [1],
    23: [1],
    24: [1],
    55: ['Other (please describe)', 'Other'],
    64: ['Other (please describe)', 'Other'],
    65: ['Other (please describe)', 'Other'],
    66: ['Other (please describe)', 'Other'],
    67: ['Other (please describe)', 'Other'],
    68: ['other'],//BII
    69: ['other'],
    // 71: ['other'],
    73: ['other'],
    74: ['Rare Disease'],
    87: ['other'],
    116: ['No'],
    118: ['other'],
    // 119: ['other'], confirmation required
    122: ['other'], //OII
    123: ['other'],
    127: ['other'],
    128: ['Rare Disease'],
    141: ['other'],
    170: ['No'],
    172: ['other'],
    // 44: ['Breast Cancer'] //barStack
    182: ['Other (Please describe)'],
    185: ['Other (Please describe)', 'Other'],
    186: ['Other (Please describe)', 'Other'],
    187: ['Other (Please describe)', 'Other'],
    189: ['Other'],
    191: ['Other'],
    192: ['Other (Please describe)', 'Other'],
    //IRA
    217: ['Other'],
    218: ['Other'],
    225: ['Other'],
    227: ['Other'],
    228: ['Other'],
    229: ['Other'],
    236: ['Moderately concerned', 'Extremely concerned'],
    //
    242: ['Other'],
    246: ['Other'],
    249: ['Other (Please describe)'],
    // 256: ['Decreased']
    259: ['Other'],
    //PPTQ
    300: ['Other'],
    293: ['Other'],
    //site-care
    277: ['Other (Please specify)', 'Other'],
    283: ['Other'],
    //clinc
    313: ['Other (Please specify)'],
    314: ['Other pathways entity (please describe)'],
    318: ['Other'],
    319: ['Other pathways entity'],
    324: ['Other pathways entity'],
    330: ['Other'],
    331: ['Other'],
    341: ['No (Please explain)'],
    346: ['Yes (please describe)'],
    350: ['Yes (Please describe)'],
    351: ['Other (please describe)'],
    //PBM
    353: ['Only mandatory for a subset of members'],
    354: ['Other'],
    356: ['Other'],
    357: ['Other'],
    359: ['Other'],
    378: ['Yes'],
    // 362: ['Other'],
    // 363: ['Other'],
    //JK
    338: ['Other (Please describe in the following question)'],
    340: ['Other (Please describe in the following question)'],
    349: ['Other (Please describe in the following question)'],
    //MBT
    414: ['Yes'],
    418: ['Yes'],
    425: ['Other (please specify)'],
    438: ['Yes, and we are actively researching/implementing', 'Yes, but only as preliminary discussions'],
    449: ['Other'],
    450: ['Other'],
    457: ['Yes'],
    458: ['Yes'],
    463: ['Other'],
    466: ['Other'],
    538: ['Yes'],
    544: ['Other'],
    551: ['Other'],
    560: ['Increase'],
    587: ['Increase'],
    603: ['Other (Please describe)'],
    597: ['Other (Please describe)', 'Other'],
    599: ['Other (Please describe)', 'Other'],
    600: ['Other (Please describe)', 'Other'],
    601: ['Other'],
    602: ['Other (Please describe)', 'Other'],
    606: ['No'],
    607: ['No'],
    608: ['No'],
    609: ['No impact', 'High impact', 'Very high impact'],
    611: ['Not at all likely', 'Very likely'],
    613: ['Yes'],
    617: ['Other'],
    623: ['No'],
    629: ['No'],
    631: ['No'],
    634: ['No'],
    641: ['Very concerned', 'Somewhat concerned'],
    642: ['No'],
    644: ['Other'],
    653: ['Yes'],
    654: ['2', '1 = not at all likely', '3', '4'],
    655: ['2', '1 = not at all likely', '3', '4', '5 = extremely likely'],
    656: ['Other'],

}